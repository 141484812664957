/**Created by liaoyingchao on 11/25/22.*/

<template>
  <div class="order page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="收货人姓名">
            <el-input v-model="form.receiverName" clearable placeholder="请输入收货人姓名"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="收货人电话">
            <el-input v-model="form.memberPhone" clearable placeholder="请输入收货人电话"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="订单日期">
            <el-date-picker
                    v-model="form.time"
                    type="date"
                    placeholder="请选择订单日期"
                    :disabled-date="disabledDate"
                    :clearable="true"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD HH:mm:ss"
            />
          </el-form-item>
          <el-form-item class="filter-item" label="订单状态">
            <el-select v-model="form.orderState" placeholder="请选择订单状态" style="width: 100%;" clearable>
              <el-option
                      v-for="item in stateOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
            <el-button :icon="Setting" @click="resetOrderMinAmountEvent">起送金额</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="orderId"
                    label="订单号"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="receiverName"
                    label="收货人姓名"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="memberPhone"
                    label="收货人电话"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="orderAddress"
                    label="收货地址"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="orderRemarks"
                    label="备注"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="orderState"
                    label="订单状态"
                    min-width="100">
              <template #default="scope">
                {{ getOrderStateText(scope.row.orderState) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="totalAmount"
                    label="总金额(元)"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="paymentAmount"
                    label="支付金额(元)"
                    min-width="110">
            </el-table-column>
            <el-table-column
                    prop="discountAmount"
                    label="优惠金额(元)"
                    min-width="110">
            </el-table-column>
            <el-table-column
                    prop="orderNumber"
                    label="商品数目"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="创建时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="修改时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            title="设置订单起送金额"
            width="500"
            destroy-on-close
    >
      <div class="dialog-content">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="140px">
          <el-form-item label="当前起送金额">
            <div>{{ editForm.orginAmount }}元</div>
          </el-form-item>
          <el-form-item label="新起送金额" prop="orderMinAmount">
            <el-input v-model="editForm.orderMinAmount" placeholder="请输入新起送金额" maxlength="10">
              <template #append>元</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">保存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue'
  import {
    Search,
    Plus,
    Setting,
  } from '@element-plus/icons-vue'
  import {ElMessage, ElMessageBox} from 'element-plus'
  import TableContainer from '@/components/table-container/index'
  import FormCheck from "@/utils/formCheck";
  import orderApi from '@/api/order'
  import {timeYYYY_MM_DD_HH_mm_ss} from '@/utils/time-format.js'
  import configApi from "../../api/config-api";

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const form = reactive({
    receiverName: '',
    memberPhone: '',
    orderState: '',
    time: ''
  })

  const disabledDate = (time) => {
    return time.getTime() > Date.now()
  }

  function getList(data) {
    if (!data.time) {
      data.time = ''
    }
    return orderApi.mgtList(data);
  }

  const stateOptions = ref([{
    label: '待支付',
    value: 10
  }, {
    label: '待配送',
    value: 20
  }, {
    label: '配送中',
    value: 30
  }, {
    label: '已完成',
    value: 40
  }, {
    label: '已取消',
    value: 50
  }, {
    label: '已退款',
    value: 60
  }])

  function getOrderStateText(state) {
    if (!state) {
      return ''
    }
    let statItem = stateOptions.value.find(item => {
      return item.value == state
    }) || {}

    return statItem.label || '';
  }

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }

  let dialogVisible = ref(false)
  const editForm = reactive({
    orginAmount: '',
    orderMinAmount: ''
  })
  const formRules = {
    orderMinAmount: [FormCheck.required('请输入新订单起送金额'), FormCheck.justPrice()]
  }
  const Ref_editForm = ref(null)

  function resetOrderMinAmountEvent() {
    configApi.getConfigValue({
      code: 'order_min_amount'
    }).then(res => {
      if (res.code == '0') {
        let conf = res.data
        editForm.orginAmount = conf.configValue
        editForm.orderMinAmount = conf.configValue

        dialogVisible.value = true;
      } else {
        ElMessage.error(res.msg || '请求接口失败！');
      }
    })
  }
  
  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        configApi.updateConfigValue({
          code: 'order_min_amount',
          value: editForm.orderMinAmount
        }).then(res => {
          if (res.code == '0') {
            ElMessage.success('设置成功！')

            dialogVisible.value = false;
          } else {
            ElMessage.error(res.msg || '请求接口失败！');
          }
        })
      } else {
        console.log('error submit!', fields)
      }
    })
  }

</script>

<style lang="stylus" scoped type="text/stylus">
  .order {
    >>> .dialog-content {
      max-height 65vh;
      overflow-y auto;
      padding-right 40px;
    }
  }
</style>