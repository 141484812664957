import http from "@/utils/http";

const configApi = {
  getConfigValue(data) {
    return http.post("/api/config/getconfigvalue", data);
  },
  updateConfigValue(data) {
    return http.post("/api/config/updateconfigvalue", data);
  },
};

export default configApi;
